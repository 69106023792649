import {
    REGISTER_SUCCESS,
    REGISTER_FAIL,
    LOGIN_SUCCESS,
    LOGIN_FAIL,
    LOGOUT,
    SET_MESSAGE,
  } from "./types";
  
import AuthService from "../services/auth-service";
import axios from "axios";
import { API_REACTIVE } from '../apis/config';
import { USER_ROLES } from "../utils/user-role";
// const API_URL = "http://linode.baggrek.com:8081/api/auth/"; 
const API_URL = API_REACTIVE.HOST + '/auth/';
  
export const register = (username, email, password) => (dispatch) => {
  return AuthService.register(username, email, password).then(
    (response) => {
      dispatch({
        type: REGISTER_SUCCESS,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: response.data.message,
      });

      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: REGISTER_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};

export const login = (username, password) => {
  return async(dispatch) => { 
    try {
      const response = await axios.post(API_URL + "login", { username, password });
      if(response) {
        if (response.data.resCode === 'E000') {        
          var user = response.data.result;
          if(user.roles.includes(USER_ROLES.SEKOLAH)) {   
            axios.get(API_REACTIVE.HOST +  API_REACTIVE.SEKOLAH_FIND_BY_ID + user.referenceId, 
            {
              headers: { Authorization: `Bearer ${user.accessToken}` }
            }).then( res => {
              const data = res.data.result;
              if(res.data.resCode === API_REACTIVE.RESPONSE_CODE_SUCCESS) {
                user = {...user, jenjang : data.jenjang, nama: data.nama};     
                localStorage.setItem("user", JSON.stringify(user));           
                
                dispatch({
                  type: LOGIN_SUCCESS,
                  payload: { user: user },
                });
                return Promise.resolve();
              }
            })  
          } 
          if(user.roles.includes(USER_ROLES.ADMIN)) {    
            localStorage.setItem("user", JSON.stringify(user));          
            dispatch({
              type: LOGIN_SUCCESS,
              payload: { user: user },
            });            
            return Promise.resolve();
          }
          if(user.roles.includes(USER_ROLES.SISWA)) { 
            localStorage.setItem("user", JSON.stringify(user));
            dispatch({
              type: LOGIN_SUCCESS,
              payload: { user: user },
            });
            
            
            return Promise.resolve();
          }
        }

       
      }  

    } catch (error) {
      const message =
      (error.response &&
        error.response.data &&
        error.response.data.message) ||
      error.message ||
      error.toString();

      dispatch({
        type: LOGIN_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  } 
};

export const logout = () => (dispatch) => {
  localStorage.clear()

  dispatch({
    type: LOGOUT,
  });
};
  