export const API_BLOCKING_URL = {
    // HOST: 'http://linode.baggrek.com:8082/api',
    // HOST: 'http://localhost.com:8082/api',
    HOST: 'https://blocking.baggrek.com/api', // => dev
    
    MATERI_SAVE: '/v1/materi',
    MATERI_DELETE: '/v1/materi/delete?id=',
    KATEGORI_MATERI_SAVE: '/v1/kategorimateri',
    KATEGORI_MATERI_DELETE: '/v1/kategorimateri/delete?id=',
    SEKOLAH_SAVE: '/v1/sekolah',
    SEKOLAH_DELETE: '/v1/sekolah/delete?id=',
    SEKOLAH_DL_REKAP: '/v1/download/report/sekolah/',
    SEKOLAH_REPORT: '/v1/sekolah/dashboard-report?sekolahId=',
    SOAL_SAVE: '/soal',
    SOAL_SAVE_IMG: '/v1/soal/upload-image/',
    SOAL_SAVE_JAWABAN_IMG: '/v1/soal/upload-image/jawaban/',
    SISWA_TRACKING_PENGERJAAN: '/v1/tracking/find?sekolahId=',
    SISWA_TRACKING_DETAIL: '/v1/tracking/detail/',
    SEKOLAH_DISABLE_REPORT: '/v1/sekolah/show-report?sekolahId=',
    RESET_PASSWORD_SEKOLAH: '/v1/sekolah/reset-password?sekolahId=',
 
    RESPONSE_CODE_SUCCESS: 'E000',
    RESPONSE_CODE_FAILED: 'E011',
    RESPONSE_CODE_ERROR_SERVICE: 'E014',
    RESPONSE_CODE_NOT_SUPPORTED: 'E016'
 }
 