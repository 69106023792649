export const API_REACTIVE_URL = {
    // HOST: 'http://linode.baggrek.com:8081/api', // prod
    // HOST: 'http://localhost:8081/api',
    HOST: 'https://core.baggrek.com/api', // => dev
 
    MATERI_LIST: '/v1/materi/find',
    MATERI_LIST_BY_JENJANG: '/v1/materi/jenjang/',
    MATERI_LIST_BY_KATEGORI: '/v1/materi/kategori/',
    MATERI_SAVE: '/v1/materi',
    MATERI_FIND_BY_ID: '/api/materi/',
    MATERI_DELETE: '/v1/materi/delete?id=',
    SEKOLAH_REPORT: '/v1/sekolah/dashboard-sekolah/',
    SISWA_TRACKING_PENGERJAAN: '/v1/tracking/find?sekolahId=',

    KATEGORI_MATERI_FIND: '/v1/kategorimateri/find',
    KATEGORI_MATERI_SAVE: '/v1/kategorimateri',
    KATEGORI_MATERI_FIND_BY_ID: '/v1/kategorimateri/',
    KATEGORI_MATERI_DELETE: '/v1/kategorimateri/delete?id=',

    SEKOLAH_FIND: '/v1/sekolah/find',
    SEKOLAH_SAVE: '/v1/sekolah',
    SEKOLAH_UPDATE: '/v1/sekolah/update',
    SEKOLAH_FIND_BY_ID: '/v1/sekolah/',
    SEKOLAH_DELETE: '/v1/sekolah/delete?id=',

    JADWAL_FIND: '/v1/jadwal/find?sekolahId=',
    JADWAL_SAVE: '/v1/jadwal?edit=',
    JADWAL_UPDATE: '/v1/jadwal?edit=',
    JADWAL_FIND_BY_ID: '/v1/jadwal/',

    SISWA_FIND: '/v1/siswa/find',
    SISWA_SAVE: '/v1/siswa',
    SISWA_FIND_BY_ID: '/siswa/',
    SISWA_COUNT_BY_SEKOLAH: '/v1/siswa/count?sekolahId=',
    SISWA_DELETE: '/v1/siswa/delete?id=',
    SISWA_RESET_PENGERJAAN: '/v1/jawaban-siswa/reset/', // jadwalid/materiid/siswaid
    SISWA_TRACKING_PENGERJAAN: '/v1/tracking/find?sekolahId=',

    SOAL_FIND: '/v1/soal/find',
    SOAL_SAVE: '/v1/soal',
    SOAL_DELETE: '/v1/soal/delete?id=',
    SOAL_SAVE_JAWABAN_TXT: '/v1/soal/update/jawaban/',
    SOAL_SAVE_JAWABAN_IMG: '/v1/soal/upload-image/jawaban/',
    SOAL_FIND_BY_ID: '/v1/soal/',
    SOAL_SAMPLE: '/v1/pengerjaan/sample-soal/',

    KELAS_FIND: '/v1/kelas/find',
    JENJANG: '/v1/common/jenjangs',
    LAPORAN: '/v1/pengerjaan/report-pengerjaan',

    SISWA_IMPORT_DOWNLOAD: '/v1/download/siswa',
    SISWA_IMPORT_UPLOAD: '/v1/upload/siswa/',
    SISWA_ENABLE_ACCOUNT: '/v1/siswa/enable-account',

    //siswas
    JADWAL_SISWA: '/v1/jadwal/test-siswa',
    PENGERJAAN_MATERI: '/v1/pengerjaan/',
    PENGERJAAN_SAMPLE_SOAL: '/v1/pengerjaan/sample-soal/',
    SAVE_JAWABAN: '/v1/jawaban-siswa/',
    POPUP_SOAL_IST_ME: '/v1/system-params/ist-me',
    TIMER_INSTRUKSI: '/v1/jawaban-siswa/instruction/',
    REPORT: '/v1/report?userId=', 

    TEST_EXCEPTION: '/test/exception',

    CHANGE_PASSWORD_USER: '/v1/user/change-password?password=', //danggoreng&user-id=1
 
    RESPONSE_CODE_SUCCESS: 'E000',
    RESPONSE_CODE_FAILED: 'E011',
    RESPONSE_EXAM_TIME_EXPIRED: 'E025',
    MAPEL_LIST: '/v1/mapel/findAll',
    MAPEL_SAVE: '/v1/mapel/save-choice',
    MAPEL_BY_ID: '/v1/mapel/',
    MAPEL_UPDATE: '/v1/mapel',
    MAPEL_DELETE: '/v1/mapel/',
    CONFIG: '/v1/system-params',
    GET_PROFILE: '/v1/user/profile/',
    EDIT_PROFILE: '/v1/siswa/edit-profile/'
 }
 